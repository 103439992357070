<template>
  <window-default ref="window" :resolve="find" :proccessData="proccessData" :mock="mock" :props="props" v-slot="{data}"
                  @loaded="(obj) => model = obj" @save="save">
    <window-body class="wrapper-lg">
      <e-row mr>
        <e-col size="1">
          <erp-s-field
              label="ID:"
          >
            <erp-input v-model="model.id" size="2" readonly />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Nome:"
          >
            <erp-input autofocus v-model="model.name" size="2" />
          </erp-s-field>
        </e-col>
      </e-row>

<!--      <e-row mr>
        <e-col>
          <erp-s-field
              label="Código Único:"
          >
            <erp-input v-model="model.code" size="2" />
          </erp-s-field>
        </e-col>
      </e-row>-->

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Descrição:"
          >
            <erp-input v-model="model.description" size="2" />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Ativo:"
          >
            <erp-checkbox v-model="model.active" />
          </erp-s-field>
        </e-col>
      </e-row>
    </window-body>
  </window-default>
</template>

<script>
import {WindowBody, ErpSField, ErpInput, ErpCheckbox} from 'uloc-vue-plugin-erp'
import WindowDefault from '../../../../../reuse/window/Default'
import ERow from '../../../../layout/components/Row'
import ECol from '../../../../layout/components/Col'
import {
  findGroup as find,
  newGroup as _new,
  updateGroup as update,
} from '../../../../../domain/project/services/memberGroup'
import save from '../../../helpers/save'

let mock = {
  id: null,
  name: null,
  description: null,
  acl: null,
  active: true
}

export default {
  name: 'Window',
  components: {ECol, ERow, WindowDefault, WindowBody, ErpSField, ErpInput, ErpCheckbox},
  props: ['props'],
  data () {
    return {
      model: JSON.parse(JSON.stringify(mock))
    }
  },
  mounted () {
  },
  computed: {
    find () {
      return find
    },
    mock () {
      return mock
    }
  },
  methods: {
    update: (id, data) => update(data),
    new: _new,
    save: save,
    proccessData (data) {
      let _mock = JSON.parse(JSON.stringify(this.mock))
      data = Object.assign(_mock, data)
      return data
    }
  }
}
</script>
